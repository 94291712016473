import React from 'react';
import { FaPhone, FaEnvelope, FaUtensils, FaCalendarAlt } from 'react-icons/fa';

const CateringContactPage: React.FC = () => {
    return (
        <div className="bg-gray-100 min-h-screen">
            {/* Hero Section */}
            <div className="bg-gray-900 text-white py-16">
                <div className="container mx-auto px-4 text-center">
                    <h1 className="text-4xl font-bold mb-4">Catering Services</h1>
                    <p className="text-xl mb-8">Bring the flavors of Greece to your next event!</p>
                    <a href="tel:+16306774147" className="text-gray-600">
                        <button className="bg-white text-blue-500 px-6 py-3 rounded-full font-semibold hover:bg-gray-200 transition duration-300">
                            Request a Quote
                        </button>
                    </a>
                </div>
            </div>

            {/* Catering Information */}
            <div className="container mx-auto px-4 py-16">
                <div className="grid md:grid-cols-2 gap-12">
                    {/* Left Column: Contact Methods */}
                    <div>
                        <h2 className="text-3xl font-bold mb-8">Get in Touch</h2>
                        <div className="space-y-6">
                            <div className="flex items-center">
                                <FaPhone className="text-2xl text-blue-500 mr-4" />
                                <div>
                                    <h3 className="text-xl font-semibold">Call Us</h3>
                                    <a href="tel:+16306774147" className="text-gray-600">
                                        +1(630)-677-4147
                                    </a>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <FaEnvelope className="text-2xl text-blue-500 mr-4" />
                                <div>
                                    <h3 className="text-xl font-semibold">Email Us</h3>
                                    <a href="mailto:lilgreekkitchen@gmail.com" className="text-gray-600">
                                        lilgreekkitchen@gmail.com
                                    </a>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <FaCalendarAlt className="text-2xl text-blue-600 mr-4" />
                                <div>
                                    <h3 className="text-xl font-semibold">Availability</h3>
                                    <p className="text-gray-600">7 days a week, Monday-Sunday</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Right Column: Catering Details */}
                    <div>
                        <h2 className="text-3xl font-bold mb-8">Our Catering Services</h2>
                        <ul className="space-y-4">
                            <li className="flex items-start">
                                <FaUtensils className="text-xl text-blue-500 mr-4 mt-1" />
                                <div>
                                    <h3 className="text-xl font-semibold">Customizable Menus</h3>
                                    <p className="text-gray-600">Tailor our Greek specialties to your event's needs</p>
                                </div>
                            </li>
                            <li className="flex items-start">
                                <FaUtensils className="text-xl text-blue-600 mr-4 mt-1" />
                                <div>
                                    <h3 className="text-xl font-semibold">Various Event Types</h3>
                                    <p className="text-gray-600">From intimate gatherings to large corporate events</p>
                                </div>
                            </li>
                            <li className="flex items-start">
                                <FaUtensils className="text-xl text-blue-500 mr-4 mt-1" />
                                <div>
                                    <h3 className="text-xl font-semibold">Full-Service Options</h3>
                                    <p className="text-gray-600">Including setup, serving, and cleanup available</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Newsletter Sign-up */}
                <div className="mt-16 bg-white rounded-lg shadow-lg p-8 text-center">
                    <h2 className="text-2xl font-bold mb-4">Stay Updated</h2>
                    <p className="mb-6">Subscribe to our newsletter for catering specials and Greek cuisine tips!</p>
                    <div className="flex max-w-md mx-auto">
                        <input
                            type="email"
                            placeholder="Enter your email"
                            className="flex-grow px-4 py-2 rounded-l-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <button className="bg-blue-500 text-white px-6 py-2 rounded-r-full hover:bg-blue-700 transition duration-300">
                            Subscribe
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CateringContactPage;