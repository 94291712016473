import React from 'react';
import { FaCalendarAlt, FaMapMarkerAlt, FaInfoCircle, FaUtensils } from 'react-icons/fa';

const PopupEventsPage: React.FC = () => {
    const events = [
        {
            business: 'Noon Whistle Brewing',
            date: 'Sept 15, 2024',
            location: 'Naperville',
            description: 'Join us for a special Greek pop-up event!'
        },
        {
            business: 'Noon Whistle Brewing',
            date: 'October 11, 2024',
            location: 'Naperville',
            description: 'Join us for a special Greek pop-up event!'
        },
        {
            business: 'Skeleton Key Brewing',
            date: 'October 17, 2024',
            location: 'Woodridge',
            description: 'Mediterranean Cooking Workshop - Learn to make classic Greek dishes'
        },
        {
            business: 'Noon Whistle Brewing',
            date: 'October 20, 2024',
            location: 'Naperville',
            description: 'Join us for a special Greek pop-up event!'
        },
        {
            business: 'Skeleton Key Brewing',
            date: 'November 1, 2024',
            location: 'Woodridge',
            description: 'Taste of Greece Food Truck Rally - Sample our best dishes on wheels'
        },
        {
            business: 'Noon Whistle Brewing',
            date: 'November 21, 2024',
            location: 'Naperville',
            description: 'Join us for a special Greek pop-up event!'
        },
        {
            business: 'Noon Whistle Brewing',
            date: 'December 8, 2024',
            location: 'Naperville',
            description: 'Join us for a special Greek pop-up event!'
        },
    ];

    return (
        <div className="bg-gray-100 min-h-screen">
            {/* Hero Section */}
            <div className="bg-gray-900 text-white py-16">
                <div className="container mx-auto px-4 text-center">
                    <h1 className="text-4xl font-bold mb-4">Upcoming Pop-up Events</h1>
                    <p className="text-xl mb-8">Join us for delicious Greek cuisine at these special events!</p>
                </div>
            </div>

            {/* Events List */}
            <div className="container mx-auto px-4 py-16">
                <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                    {events.map((event, index) => (
                        <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
                            <div className="p-6">
                                <h3 className="text-2xl font-bold mb-4 text-blue-600">{event.business}</h3>
                                <div className="flex items-center mb-3">
                                    <FaCalendarAlt className="text-xl text-blue-500 mr-3" />
                                    <p className="text-large font-bold text-gray-700">{event.date}</p>
                                </div>
                                <div className="flex items-center mb-3">
                                    <FaMapMarkerAlt className="text-xl text-blue-500 mr-3" />
                                    <p className="text-large font-bold text-gray-700">{event.location}</p>
                                </div>
                                <div className="flex items-start mb-4">
                                    <FaInfoCircle className="text-xl text-blue-500 mr-3 mt-1" />
                                    <p className="text-gray-600">{event.description}</p>
                                </div>
                                <div className="flex items-start mb-4">
                                    <a href="#" className="inline-flex  items-start text-blue-500 hover:text-blue-700">
                                        <FaUtensils className="text-xl text-blue-500 mr-3 mt-1" />
                                        Menu (Coming Soon)
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Call-to-Action */}
            <div className="bg-white py-16">
                <div className="container mx-auto px-4 text-center">
                    <h2 className="text-3xl font-bold mb-4">Don't Miss Out!</h2>
                    <p className="text-xl mb-8">Stay updated on all our pop-up events and exclusive offers.</p>
                    <div className="flex max-w-md mx-auto">
                        <input
                            type="email"
                            placeholder="Enter your email"
                            className="flex-grow px-4 py-2 rounded-l-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <button className="bg-blue-500 text-white px-6 py-2 rounded-r-full hover:bg-blue-700 transition duration-300">
                            Subscribe
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PopupEventsPage;