import React from 'react';
import images from './constants/images';

const AboutUsPage: React.FC = () => {
    return (
        <div className="bg-gray-100 min-h-screen font-body">
            {/* Hero Section */}
            <div className="relative">
                <img src={images.hold2} loading='lazy' alt="About Us hero" className="w-full h-96 object-cover" />
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="absolute inset-0 flex flex-col items-center justify-center">
                    <h1 className="text-5xl font-bold text-white text-center mb-3">Locally Owned</h1>
                    <h2 className="text-2xl font-medium text-white text-center">Chicago Based Greek Cuisine</h2>
                </div>
            </div>

            {/* Main Content Section */}
            <div className="container mx-auto px-4 py-12">
                <div className="grid md:grid-cols-2 gap-8">
                    {/* Left Column */}
                    <div className="bg-white rounded-lg shadow-lg p-8">
                        <h2 className="text-3xl font-semibold mb-6 text-blue-500 font-heading">A Few Words About Us</h2>
                        <p className="text-gray-700 leading-relaxed">
                            Lil' Greek Kitchen is a locally owned and operated restaurant that brings delicious,
                            authentic Greek cuisine to the Chicagoland area. We take pride in offering the best
                            food to you with every order. Our passion for Greek culinary traditions shines through
                            in every dish we serve.
                        </p>
                        <p className="text-gray-700 leading-relaxed mt-4">
                            Whether you're taking out, or ordering delivery, we're here to satisfy your
                            craving for genuine Greek flavors. Experience the taste of Greece right here in Chicago!
                        </p>
                    </div>

                    {/* Right Column */}
                    <div className="bg-white rounded-lg shadow-lg p-8">
                        <h2 className="text-3xl font-semibold mb-6 text-blue-500 font-heading">We Value Authenticity and Quality</h2>
                        <p className="text-gray-700 leading-relaxed">
                            Each dish at Lil' Greek Kitchen is crafted with care to ensure your complete satisfaction.
                            We use only the finest ingredients and follow time-honored recipes to create meals that
                            transport you straight to the heart of Greece.
                        </p>
                        <p className="text-gray-700 leading-relaxed mt-4">
                            Our commitment to quality and authenticity means you'll enjoy a true Greek dining experience
                            with every bite. We're confident that once you taste our food, you'll be coming back for more!
                        </p>
                        <a href="https://www.ezcater.com/catering/pvt/lil-greek-kitchen-3">
                            <button className="mt-8 bg-blue-500 text-white px-6 py-3 rounded-full font-semibold hover:bg-blue-400 transition duration-300">
                                Order Now
                            </button>
                        </a>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUsPage;