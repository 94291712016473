import React, { useState, useEffect } from 'react';

interface Review {
    name: string;
    date: string;
    location: string;
    content: string;
}

const reviews: Review[] = [
    {
        name: "DePaul Basketball Team",
        date: "09/04/24",
        location: "Chicago, IL",
        content: "The best greek food around. The build your own gyro with multiple protein options is what we enjoy most. We'll be ordering again soon."
    },
    {
        name: "Buyer's Edge Platform",
        date: "09/03/24",
        location: "Chicago, IL",
        content: "This was awesome! The office loved it! Thank you!"
    },
    {
        name: "Paula",
        date: "07/30/24",
        location: "Arlington Heights, IL",
        content: "Healthy and fresh, tastes delicious and great presentation. Love their food"
    },
    {
        name: "Paula",
        date: "07/17/24",
        location: "Elk Grove Village, IL",
        content: "Always a favorite. Healthy good that tastes great. Beautiful presentation and friendly staff delivers their own food"
    },
    {
        name: "Sammie",
        date: "05/22/24",
        location: "Lincolnshire, IL",
        content: "Excellent lunch from Little Greek Fresh Grill! Reliable caterer and on-time delivery! We can't wait to order again!"
    },
    {
        name: "Jennifer",
        date: "07/11/23",
        location: "Carpentersville, IL",
        content: "Our order was delivered on time and hot. Food was delicious (as always - this was my second order from them). I highly recommend Little Greek Fresh Grill for the quality of their food, timeliness, and assistance in helping me set up. Thank you!"
    },
    {
        name: "Stephanie",
        date: "04/15/24",
        location: "Elgin, IL",
        content: "Food was great. They were on time and had all the utensils."
    },

]
const ReviewsCarousel: React.FC = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length);
    };

    useEffect(() => {
        const timer = setInterval(nextSlide, 5000);
        return () => clearInterval(timer);
    }, []);

    return (
        <div className="bg-gray-100 py-12">
            <div className="max-w-4xl mx-auto px-4">
                <h2 className="text-3xl font-bold text-center mb-8">What Our Customers Say</h2>
                <div className="relative bg-white rounded-lg shadow-lg p-8">
                    <svg className="w-12 h-12 text-blue-300 mb-4 mx-auto" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z" />
                    </svg>
                    <p className="text-lg text-gray-700 text-center mb-6">{reviews[currentIndex].content}</p>
                    <div className="text-center">
                        <p className="font-semibold text-blue-600">{reviews[currentIndex].name}</p>
                        <p className="text-sm text-gray-500">{reviews[currentIndex].location} • {reviews[currentIndex].date}</p>
                    </div>
                    <button
                        onClick={prevSlide}
                        className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md text-blue-500 hover:text-blue-600 transition-colors duration-300"
                    >
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                        </svg>
                    </button>
                    <button
                        onClick={nextSlide}
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md text-blue-500 hover:text-blue-600 transition-colors duration-300"
                    >
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                    </button>
                </div>
                <div className="flex justify-center mt-4">
                    {reviews.map((_, index) => (
                        <button
                            key={index}
                            onClick={() => setCurrentIndex(index)}
                            className={`h-3 w-3 rounded-full mx-1 transition-colors duration-300 ${index === currentIndex ? 'bg-blue-500' : 'bg-gray-300 hover:bg-blue-200'
                                }`}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ReviewsCarousel;